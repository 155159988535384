/* 
BG Color/ Teal: #298c88
Text Color/ Dark Blue: #17285a
*/


.content {
  /* background-color: #; */
}

.greeting {
  border: 5px solid green;
  color: purple;
}