.general-text {
    color: white;
}

.statement {
    text-transform: uppercase;
    color:white;
    font-weight: 700;
    margin-bottom: 0;
}

.statement2 {
    font-weight: 700;
    text-transform: uppercase;
    color: #e9c46a
}

.submit-button-amazon {
    text-decoration: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    background-color: #ba8d1b;
    color: white;
    box-shadow: 0 6px rgb(109, 109, 109);
}

.submit-button-amazon:hover {
    background-color: #815f0a
}

.submit-button-amazon:active {
  background-color: #815f0a;
  box-shadow: 0 3px rgb(80, 80, 80);
  transform: translateY(4px);
}


@media only screen 
and (min-width : 100px)
and (max-width : 759px) {

    .statement {
        font-size: 2rem;
    }

    .statement2 {
        margin-top: 0;
        font-size: 2rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 300px;
    }

    .general-text {
        font-size: 18px;
    }

    .submit-button-amazon {
        font-size: 14px;
    }

}


/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {
    .statement {
        font-size: 3rem;
    }

    .statement2 {
        margin-top: 0;
        font-size: 3rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 600px;
    }

    .general-text {
        font-size: 24px;
    }

    .submit-button-amazon {
        font-size: 24px;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {
    .statement {
        font-size: 2.7rem;
        margin-bottom: 0;
    }

    .statement2 {
        margin-top: 0;
        font-size: 2.7rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 900px;
    }

    .general-text {
        font-size: 30px;
        line-height: 40px;
    }

    .submit-button-amazon {
        font-size: 42px;
    }
}


