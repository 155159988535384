.name-box {
    display: flex;
    flex-direction: column;
}

.contactTheAuthor-form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name-label {
    align-self: start;
    color: white;
    margin-bottom: .2rem;
}

.name-input {
    padding: 1rem .7rem;
    border-radius: 5px;
    border: none;
    outline-color: #17285a;
}

.message-input {
    padding: 1rem .7rem;
    border-radius: 5px;
    border: none;
    min-width: inherit;
    height: 5rem;
    resize: none;
    outline-color: #17285a;
}

.statement-container-email {
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.submit-button-author {
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    background-color: #ba8d1b;
    color: white;
    box-shadow: 0 6px rgb(109, 109, 109);
}

.submit-button-author:hover {
    cursor: pointer;
    background-color: #815f0a
}

.submit-button-author:active {
  background-color: #815f0a;
  box-shadow: 0 3px rgb(80, 80, 80);
  transform: translateY(4px);
}




@media only screen 
and (min-width : 100px)
and (max-width : 759px) {

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 300px;
    }

    .submit-button-author {
        font-size: 14px;
    }

    .name-label {
        font-size: 16px;
    }

    .name-input {
        font-size: 14px;
    }

    .message-input {
        font-size: 14px;
    }
}

/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 600px;
    }

    .submit-button-author {
        font-size: 18px;
    }

    .name-label {
        font-size: 22px;
    }

    .name-input {
        font-size: 18px;
    }

    .message-input {
        font-size: 18px;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {

    .profile-desktop {
        
    }

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 900px;
    }

    .submit-button-author {
        font-size: 22px;
    }

    .name-label {
        font-size: 26px;
    }

    .name-input {
        font-size: 22px;
    }

    .message-input {
        font-size: 22px;
    }
}