body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #264653;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
BG Color/ Teal: #298c88
Text Color/ Dark Blue: #17285a
*/


.content {
  /* background-color: #; */
}

.greeting {
  border: 5px solid green;
  color: purple;
}
/* .headline {
    color: #17285a;
    font-size: 3rem;
    text-align: center;
} */

.headline-book-name {
    color: white; 
}

.headline-subHeader {
    color: white;
}

.headline-name {
    color: #e9c46a;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-width : 100px)
and (max-width : 759px) {
    .headline-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .headline-book {
        padding-top: 3rem;
        width: 80%;
    }

    .headline-book-name {
        font-size: 3rem;
        text-align: center;
    }

    .headline-sub-container {
        margin: 0;
        margin-top: .5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .headline-name {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
    }
}


/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {
    .headline-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0 2rem;
    }

    .headline-book {
        padding-top: 3rem;
        width: 50%;
    }

    .headline-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }


    .headline-book-name {
        font-size: 4rem;
        width: 30%;
        text-align: left;
        /* text-align: center; */
    }

    .headline-subHeader {
        margin: 0;
        margin-top: .5rem;
        font-size: 2rem;
    }

    .headline-name {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        font-size: 2rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {
    .headline-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 5rem 2rem;
        max-width: 900px;
        margin: 0 auto;
    }

    .headline-book {
        padding-top: 3rem;
        width: 50%;
    }

    .headline-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }


    .headline-book-name {
        font-size: 4rem;
        width: 30%;
        /* text-align: center; */
        margin-bottom: 20px;
        font-size: 75px;
        color:white
    }

    .headline-subHeader {
        margin: 0;
        margin-top: .5rem;
        font-size: 2rem;
    }

    .headline-name {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        font-size: 2rem;
    }
}















/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
    /* Styles */
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
    /* Styles */
}
.name-box {
    display: flex;
    flex-direction: column;
}

.contactTheAuthor-form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name-label {
    align-self: start;
    color: white;
    margin-bottom: .2rem;
}

.name-input {
    padding: 1rem .7rem;
    border-radius: 5px;
    border: none;
    outline-color: #17285a;
}

.message-input {
    padding: 1rem .7rem;
    border-radius: 5px;
    border: none;
    min-width: inherit;
    height: 5rem;
    resize: none;
    outline-color: #17285a;
}

.statement-container-email {
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.submit-button-author {
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    background-color: #ba8d1b;
    color: white;
    box-shadow: 0 6px rgb(109, 109, 109);
}

.submit-button-author:hover {
    cursor: pointer;
    background-color: #815f0a
}

.submit-button-author:active {
  background-color: #815f0a;
  box-shadow: 0 3px rgb(80, 80, 80);
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}




@media only screen 
and (min-width : 100px)
and (max-width : 759px) {

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 300px;
    }

    .submit-button-author {
        font-size: 14px;
    }

    .name-label {
        font-size: 16px;
    }

    .name-input {
        font-size: 14px;
    }

    .message-input {
        font-size: 14px;
    }
}

/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 600px;
    }

    .submit-button-author {
        font-size: 18px;
    }

    .name-label {
        font-size: 22px;
    }

    .name-input {
        font-size: 18px;
    }

    .message-input {
        font-size: 18px;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {

    .profile-desktop {
        
    }

    .email-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 900px;
    }

    .submit-button-author {
        font-size: 22px;
    }

    .name-label {
        font-size: 26px;
    }

    .name-input {
        font-size: 22px;
    }

    .message-input {
        font-size: 22px;
    }
}
.profile {
    border-radius: 50%;
    width: 50%;
}



.name-text {
    color: #e9c46a;
}


.general-text-author {
    text-align: left;
    color: white;
    font-size: 30px;
    line-height: 40px;
}



@media only screen 
and (min-width : 100px)
and (max-width : 759px) {
    .name-text {
        font-size: 20px;
    }

    .about-author-container-desktop {
        display: none;
    }

    .about-author-container {
        display: auto;
    }
}

/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1300px)  {
    .name-text {
        font-size: 30px;
    }

    .about-author-container-desktop {
        display: none;
    }

    .about-author-container {
        display: auto;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1300px) {
    .name-text {
        font-size: 40px;
    }

    .about-author-container-desktop {
        display: auto;
    }

    
    .about-author-container {
        display: none;
    }

    .statement-container-desktop {
        display: flex;
        justify-content: row;
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .profile-desktop {
        width: 60%;
        margin-left: -25%;
    }

    .about-author-desktop {
        margin-right: -20%;

    }
}
.chapterGuide-title-container {
    margin-top: 5rem;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.chapterGuide-title {
    text-transform: uppercase;
    color:white;
    font-weight: 700;
    margin-bottom: 0;
}

.part-header {
    color: #e9c46a;
}

.part-header-secondary {
    color: #e9c46aca;
}

.chapter-guide-text {
    text-indent: 1rem;
    color: white;
    font-weight: 500;
}

@media only screen 
and (min-width : 100px)
and (max-width : 759px) {
    .chapterGuide-title {
        font-size: 2rem;
    }

    .chapterGuide-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 300px;
    }

    .part-header {
        font-size: 24px;
    }

    .part-header-secondary {
        font-size: 24px;
    }

    .chapter-guide-text {
        font-size: 16px;
    }

}


/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {
    .chapterGuide-title {
        font-size: 3rem;
    }

    .chapterGuide-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 600px;
    }

    .part-header {
        font-size: 32px;
    }

    .part-header-secondary {
        font-size: 32px;
    }

    .chapter-guide-text {
        font-size: 20px;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {
    .chapterGuide-title {
        font-size: 4rem;
    }

    .chapterGuide-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 900px;
    }

    .part-header {
        font-size: 44px;
    }

    .part-header-secondary {
        font-size: 44px;
    }

    .chapter-guide-text {
        font-size: 26px;
    }
}



.general-text {
    color: white;
}

.statement {
    text-transform: uppercase;
    color:white;
    font-weight: 700;
    margin-bottom: 0;
}

.statement2 {
    font-weight: 700;
    text-transform: uppercase;
    color: #e9c46a
}

.submit-button-amazon {
    text-decoration: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    background-color: #ba8d1b;
    color: white;
    box-shadow: 0 6px rgb(109, 109, 109);
}

.submit-button-amazon:hover {
    background-color: #815f0a
}

.submit-button-amazon:active {
  background-color: #815f0a;
  box-shadow: 0 3px rgb(80, 80, 80);
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}


@media only screen 
and (min-width : 100px)
and (max-width : 759px) {

    .statement {
        font-size: 2rem;
    }

    .statement2 {
        margin-top: 0;
        font-size: 2rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 300px;
    }

    .general-text {
        font-size: 18px;
    }

    .submit-button-amazon {
        font-size: 14px;
    }

}


/* Tablet (portrait and landscape) ----------- */
@media only screen 
and (min-width : 760px)
and (max-width: 1023px)  {
    .statement {
        font-size: 3rem;
    }

    .statement2 {
        margin-top: 0;
        font-size: 3rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 600px;
    }

    .general-text {
        font-size: 24px;
    }

    .submit-button-amazon {
        font-size: 24px;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {
    .statement {
        font-size: 2.7rem;
        margin-bottom: 0;
    }

    .statement2 {
        margin-top: 0;
        font-size: 2.7rem; 
    }

    .statement-container {
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        margin-bottom: 3rem;
    }

    .bookSummary-container {
        padding: 1rem;
        margin: 20px auto;
        max-width: 900px;
    }

    .general-text {
        font-size: 30px;
        line-height: 40px;
    }

    .submit-button-amazon {
        font-size: 42px;
    }
}



